<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col class="pr-12">
          <h1>{{ isEditing ? "Update an" : "Create a New" }} Article</h1>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn depressed light :to="{ name: parentRouteName }">
              <v-icon small left>mdi-arrow-left</v-icon> Back to Articles
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-divider class="mt-2 mb-6"></v-divider>
      <v-select
        label="Template Type"
        :items="types"
        v-model="fields.template_type"
        item-value="value"
        item-text="label"
        outlined
      ></v-select>
      <v-card outlined v-if="fields.template_type">
        <v-toolbar flat dark dense color="accent">
          <v-toolbar-title>Article</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-4">
          <v-text-field
            label="Title *"
            v-model="fields.title"
            outlined
            :error="errors.hasOwnProperty('title')"
            :error-messages="errors['title']"
          ></v-text-field>
          <!-- <v-text-field
            label="Start Date"
            v-model="fields.start_date"
            type="date"
            outlined
            dense
            :error="errors.hasOwnProperty('start_date')"
            :error-messages="errors['start_date']"
            v-if="fields.template_type === 'wysiwyg'"
          ></v-text-field>
          <v-text-field
            label="End Date"
            v-model="fields.end_date"
            type="date"
            outlined
            dense
            :error="errors.hasOwnProperty('end_date')"
            :error-messages="errors['end_date']"
            v-if="fields.template_type === 'wysiwyg'"
          ></v-text-field>-->
          <v-text-field
            label="Slug *"
            @change="onChange($event)"
            v-model="slug"
            outlined
            dense
            :error="errors.hasOwnProperty('slug')"
            :error-messages="errors['slug']"
            v-if="
              fields.template_type === 'wysiwyg' ||
              fields.template_type === 'video'
            "
          ></v-text-field>
          <v-select
            label="Categories *"
            :items="categories"
            v-model="fields.categories"
            item-value="id"
            item-text="title"
            outlined
            multiple
          ></v-select>
          <v-textarea
            label="Short Description *"
            v-model="fields.short_description"
            rows="3"
            outlined
            :error="errors.hasOwnProperty('short_description')"
            :error-messages="errors['short_description']"
            v-if="fields.template_type === 'wysiwyg'"
          ></v-textarea>

          <tiptap
            class="mb-5"
            label="Content *"
            v-model="fields.content"
            :error="errors.hasOwnProperty('content')"
            :error-messages="errors['content']"
            v-if="fields.template_type === 'wysiwyg'"
          />

          <v-text-field
            label="Youtube Video ID *"
            v-model="fields.video_id"
            outlined
            :error="errors.hasOwnProperty('video_id')"
            :error-messages="errors['video_id']"
            v-if="fields.template_type === 'video'"
          ></v-text-field>
          <v-text-field
            label="Video Length *"
            v-model="fields.video_length"
            hint="X Mins Y Secs"
            persistent-hint
            outlined
            :error="errors.hasOwnProperty('video_length')"
            :error-messages="errors['video_length']"
            v-if="fields.template_type === 'video'"
          ></v-text-field>
          <v-file-input
            label="Main Image"
            v-model="fields.main_image"
            accept="image/png, image/jpeg"
            placeholder="Choose a Main Image"
            outlined
            @change="mainImageHasChanged()"
            :error="errors.hasOwnProperty('main_image')"
            :error-messages="errors['main_image']"
            v-if="fields.template_type === 'wysiwyg'"
          ></v-file-input>
          <v-file-input
            label="Thumbnail Image"
            v-model="fields.thumbnail"
            accept="image/png, image/jpeg"
            placeholder="Choose a Thumbnail Image"
            outlined
            @change="thumbnailHasChanged()"
            :error="errors.hasOwnProperty('thumbnail')"
            :error-messages="errors['thumbnail']"
            v-if="
              fields.template_type === 'wysiwyg' ||
              fields.template_type === 'video' ||
              fields.template_type === 'download'
            "
          ></v-file-input>

          <v-switch
            label="Featured"
            v-model="fields.is_featured"
            :error="errors.hasOwnProperty('is_featured')"
            :error-messages="errors['is_featured']"
            inset
            v-if="fields.template_type === 'video'"
          ></v-switch>

          <v-file-input
            label="Attached Document"
            v-model="fields.attached_document"
            accept="application/pdf"
            placeholder="Choose a File"
            outlined
            @change="attachedDocumentHasChanged()"
            :error="errors.hasOwnProperty('attached_document')"
            :error-messages="errors['attached_document']"
            v-if="
              fields.template_type === 'wysiwyg' ||
              fields.template_type === 'download'
            "
          ></v-file-input>
          <v-card
            color="grey lighten-2"
            outlined
            v-if="fields.template_type === 'q-a'"
          >
            <v-card-title>Question & Answers</v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pb-0">
              <v-card
                outlined
                v-for="(question, index) in questions"
                :key="index"
                class="mb-4"
              >
                <v-card-subtitle
                  >Question {{ index + 1
                  }}{{
                    questions[index].title !== ""
                      ? `: ${questions[index].title}`
                      : ""
                  }}</v-card-subtitle
                >
                <v-divider></v-divider>
                <v-card-text>
                  <v-text-field
                    label="Question *"
                    v-model="questions[index].title"
                    outlined
                    dense
                    background-color="white"
                  ></v-text-field>
                  <v-textarea
                    label="Answer *"
                    v-model="questions[index].content"
                    outlined
                    dense
                    hide-details
                    background-color="white"
                  ></v-textarea>
                </v-card-text>
                <v-card-actions class="px-4 pb-4">
                  <v-btn
                    depressed
                    color="red lighten-4 red--text"
                    @click="deleteQuestion(index)"
                    ><v-icon left>mdi-delete</v-icon> Delete Question</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-card-text>
            <v-card-actions class="px-4 pb-4">
              <v-btn depressed @click="addQuestion()"
                ><v-icon left>mdi-plus</v-icon> Add Question</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-card-text>
        <v-card-actions class="px-4 pb-4">
          <v-btn
            depressed
            large
            color="accent"
            :loading="loading"
            @click="save()"
            ><v-icon left>mdi-content-save</v-icon> Save Article</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-alert type="info" v-else>Please Select a Template Type</v-alert>
    </v-container>
  </div>
</template>

<script>
import Tiptap from "@/views/components/Tiptap.vue";

export default {
  components: {
    Tiptap,
  },
  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
    parentRouteName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      types: [
        { value: "wysiwyg", label: "Article" },
        { value: "video", label: "Video" },
        { value: "download", label: "Download" },
      ],
      fields: {
        template_type: null,
        title: null,
        slug: null,
        start_date: "",
        end_date: "",
        categories: [],
        short_description: null,
        content: null,
        video_id: null,
        video_length: null,
        thumbnail: null,
        thumbnail_has_changed: false,
        main_image: null,
        main_image_has_changed: false,
        attached_document: null,
        attached_document_has_changed: false,
        is_featured: false,
      },
      loading: false,
      questions: [],
      errors: {},
    };
  },

  mounted() {
    if (this.isEditing) {
      this.fields.template_type = this.article.template_type;
      this.fields.title = this.article.title;
      this.fields.slug = this.article.slug;

      // this.fields.start_date = this.article.start_date;
      // this.fields.end_date = this.article.end_date ? this.article.end_date : "";

      this.fields.categories = this.article.categories.map((c) => c.id);

      this.fields.thumbnail = this.article.thumbnail_url
        ? new File([this.article.thumbnail_url], this.article.thumbnail_url)
        : null;
      this.fields.main_image = this.article.main_image_url
        ? new File([this.article.main_image_url], this.article.main_image_url)
        : null;
      this.fields.attached_document = this.article.attached_document
        ? new File(
            [this.article.attached_document],
            this.article.attached_document
          )
        : null;

      if (this.article.template_type === "video") {
        this.fields.video_id = this.article.template_fields.id;
        this.fields.video_length = this.article.template_fields.length;

        this.fields.is_featured = this.article.is_featured;
      } else if (this.article.template_type === "q-a") {
        for (let index in this.article.template_fields) {
          let question = this.article.template_fields[index];

          this.questions.push({
            title: question.title,
            content: question.content,
          });
        }
      } else {
        this.fields.content = this.article.body;
        this.fields.short_description = this.article.short_description;
      }
    }
  },

  computed: {
    article() {
      return this.$store.state.grogreen.articles["article"];
    },

    categories() {
      return this.$store.state.grogreen.articles["categories"];
    },

    slug: {
      get() {
        if (this.isEditing) {
          return this.fields.slug;
        }

        let slug = this.fields.title;

        if (slug === "" || slug === null) {
          return slug;
        }

        slug = slug.trim();
        slug = slug.replaceAll("&", "and");
        slug = slug.replaceAll(",", "");
        slug = slug.replaceAll(/[^a-zA-Z ]/g, "");
        slug = slug.replaceAll(" ", "-");
        slug = slug.toLowerCase();
        if (slug.substr(-1) === "-") {
          slug = slug.substr(0, slug.length - 1);
        }

        return slug;
      },
      set(value) {
        return value;
      },
    },
  },

  watch: {
    slug(value) {
      this.fields.slug = value;
    },
  },

  methods: {
    onChange(event) {
      this.fields.slug = event;
    },

    addQuestion() {
      this.questions.push({
        title: "",
        content: "",
      });
    },

    deleteQuestion(index) {
      this.questions.splice(index, 1);
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.errors = {};
      this.fields = {
        template_type: null,
        title: null,
        slug: null,
        start_date: "",
        end_date: "",
        categories: [],
        short_description: null,
        content: null,
        video_id: null,
        video_length: null,
        thumbnail: null,
        thumbnail_has_changed: false,
        main_image: null,
        main_image_has_changed: false,
        attached_document: null,
        attached_document_has_changed: false,
        is_featured: false,
      };
    },

    save() {
      const appId = this.$route.params.id;
      this.loading = true;
      this.errors = {};

      let formData = new FormData();
      formData.append("title", this.fields.title);
      formData.append("slug", this.fields.slug);
      formData.append("start_date", this.fields.start_date);
      formData.append("end_date", this.fields.end_date);
      formData.append("template_type", this.fields.template_type);
      formData.append("is_featured", this.fields.is_featured ? 1 : 0);

      for (let i = 0; i < this.fields.categories.length; i++) {
        formData.append("categories[]", this.fields.categories[i]);
      }

      formData.append(
        "thumbnail_has_changed",
        this.fields.thumbnail_has_changed ? 1 : 0
      );
      if (this.fields.thumbnail_has_changed) {
        formData.append("thumbnail", this.fields.thumbnail);
      }

      formData.append(
        "main_image_has_changed",
        this.fields.main_image_has_changed ? 1 : 0
      );
      if (this.fields.main_image_has_changed) {
        formData.append("main_image", this.fields.main_image);
      }

      formData.append(
        "attached_document_has_changed",
        this.fields.attached_document_has_changed ? 1 : 0
      );
      if (this.fields.attached_document_has_changed) {
        formData.append("attached_document", this.fields.attached_document);
      }

      if (this.fields.template_type === "video") {
        formData.append("video_id", this.fields.video_id ?? "");
        formData.append("video_length", this.fields.video_length ?? "");
      } else if (this.fields.template_type === "q-a") {
        for (let i = 0; i < this.questions.length; i++) {
          formData.append(`questions[${i}][title]`, this.questions[i]["title"]);
          formData.append(
            `questions[${i}][content]`,
            this.questions[i]["content"]
          );
        }
      } else {
        formData.append("content", this.fields.content);
        formData.append("short_description", this.fields.short_description);
      }

      let payload = {
        appId,
        fields: formData,
        isEditing: false,
      };

      if (this.isEditing) {
        payload.isEditing = true;
        payload.articleId = this.article.id;
      }

      this.$store
        .dispatch("grogreen/articles/saveArticle", {
          ...payload,
        })
        .then((resp) => {
          this.loading = false;
          this.$store.commit("showGlobalSnackbar", {
            open: true,
            text: "Article Successfully Saved!",
            color: "success",
          });
          if (this.isEditing === false) {
            this.$router.push({
              name: this.parentRouteName + "-individual",
              params: { articleId: resp.data.article.id },
            });
          }
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    thumbnailHasChanged() {
      this.fields.thumbnail_has_changed = true;
    },

    mainImageHasChanged() {
      this.fields.main_image_has_changed = true;
    },

    attachedDocumentHasChanged() {
      this.fields.attached_document_has_changed = true;
    },
  },
};
</script>
